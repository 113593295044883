import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import logo from '@app/assets/images/logo.png';

const AboutUs: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.AboutUs',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 lg:px-0 xl:max-w-container-xl text-lg">
        <img className="m-auto" src={logo} width={300} />

        <h1 className="mt-20 mb-5 font-bold text-center">About Us</h1>

        <div className="who-am-i-description">
          <p className="mb-4 w-80 m-auto text-center mb-20">
            Hairmedicareturkey Plastic and Aesthetic Surgeon Dr. Serdar Bora
            Bayraktaroğlu and Anesthesia and Reanimation Specialist Dr. Merih
            Tombul Hair transplantation and trichological hair treatments
            founded by Yesildal It is a center made in our clinic with a
            specialist doctor. Your consultation and hair treatment will be
            done.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
